import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import { Layout } from '../components/Layout';
import { MDXRenderer } from "gatsby-plugin-mdx"
import LocalizedLink from '../components/LocalizedLink';
import MdxLink from '../components/MdxLink';
import translations from '../components/useTranslation';

const Home = props => {
  const intro = props.data.intro;
  const site = props.data.site.siteMetadata;
  const features = props.data.features.edges;
  const introImageClasses = "intro-image " + (intro.frontmatter ? `${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}` : "");
  const { metaDescription, shareSkills, joinOurCause, viewAll, resources } = translations();

  return (
    <>
      <SEO title={site.title} />
      <Helmet>
        <meta
          name="description"
          content={metaDescription}
        />
      </Helmet>

      <div className="intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <h1>Free Belarus Coalition</h1>
              <p>{ metaDescription }</p>
              <div className="call">
                <div className="call-box-top">
                  <div className="call-phone">
                    <strong>{shareSkills}</strong>
                  </div>
                </div>
                <div className="call-box-bottom">
                  <LocalizedLink to="/join" className="button">{joinOurCause}</LocalizedLink>
                </div>
              </div>
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container pb-md-10">
        <div className="col-12 col-md-8">
	      <div class="content"><MDXRenderer components={{ a: MdxLink }}>{intro.body}</MDXRenderer></div>
        </div>
      </div>

      {features.length > 0 && (
        <div className="strip strip-grey">
          <div className="container pt-6 pb-6 pt-md-10 pb-md-10">
            <div className="row justify-content-center">
              {features.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                  <div className="feature">
                    {node.image && (
                      <div className="feature-image">
                        <img src={node.image} />
                      </div>
                    )}
                    <h2 className="feature-title">{node.title[intro.fields.locale]}</h2>
                    <div className="feature-content">{node.description[intro.fields.locale]}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const query = graphql`
  query IndexQuery($locale: String!) {
    intro: mdx(
      fields: { locale: { eq: $locale } }
      fileAbsolutePath: {regex: "/content\/index....mdx$/"}
	) {
        body
        fields {
          locale
        }
        frontmatter {
          intro_image
          intro_image_absolute
          intro_image_hide_on_mobile
          title
        }
    }
    features: allFeaturesJson {
      edges {
        node {
          id
          title {
            en
            by
          }
          description {
            en
            by
          }
          image
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
